
import { defineComponent, PropType } from 'vue'
import BaseModal from '../reusable/BaseModal.vue'

import { SimplifiedUserColumnData } from '@/views/ManageUsers.vue'

export default defineComponent({
  components: { BaseModal },
  emits: ['close', 'confirm'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object as PropType<SimplifiedUserColumnData>,
      required: true,
    },
  },
})
