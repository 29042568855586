<template>
  <base-modal :show="show">
    <div
      v-if="loading"
      class="min-w-full h-64 flex justify-center items-center"
    >
      <p class="text-gray-600">Creating user. Please wait ...</p>
    </div>
    <div v-else class="bg-white px-4 pt-5 pb-8 sm:p-8">
      <div class="mt-3 text-center sm:mt-0 sm:text-left">
        <h3
          class="text-lg leading-6 font-medium text-gray-900"
          id="modal-headline"
        >
          Create a new user
        </h3>
        <div class="w-full mt-6">
          <p class="text-sm">Full Name</p>
          <input
            v-model="fullName"
            type="text"
            name="name"
            id="name"
            class="w-full mt-2 border rounded px-3 py-2 focus:border-brand-light outline-none"
          />
        </div>
        <div class="w-full mt-4">
          <p class="text-sm">Email address</p>
          <input
            v-model="email"
            type="text"
            name="email"
            id="email"
            class="w-full mt-2 border rounded px-3 py-2 focus:border-brand-light outline-none"
          />
        </div>
        <div class="w-full mt-4">
          <p class="text-sm">Password</p>
          <input
            v-model="password"
            type="password"
            name="new-password"
            id="new-password"
            autocomplete="new-password"
            class="w-full mt-2 border rounded px-3 py-2 focus:border-brand-light outline-none"
          />
        </div>
        <div class="w-full mt-4">
          <p class="text-sm">Repeat password</p>
          <input
            v-model="repeatPassword"
            type="password"
            name="repeat-password"
            id="repeat-password"
            autocomplete="new-password"
            class="w-full mt-2 border rounded px-3 py-2 focus:border-brand-light outline-none"
            @keyup.enter="create"
          />
        </div>
        <div class="mt-4">
          <p class="text-sm">Role for Authorization Level</p>
          <select
            v-model="role"
            name="role"
            id="role"
            class="mt-2 border rounded px-3 py-2 focus:border-brand-light outline-none"
          >
            <option
              v-for="item in roleOptions"
              :key="item.title"
              :value="item.value"
              >{{ item.title }}</option
            >
          </select>
        </div>
        <ul
          v-if="errors.length"
          class="mt-6 ml-4 text-sm text-red-600 mr-3 list-disc"
        >
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </div>
    </div>
    <div
      class="mt-2 bg-gray-50 px-4 py-3 sm:px-6 sm:pb-6 sm:flex sm:flex-row-reverse"
    >
      <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
        <button
          :disabled="loading"
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5 disabled:bg-gray-400"
          @click="create"
        >
          Create
        </button>
      </span>
      <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
        <button
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          @click="$emit('close')"
        >
          Cancel
        </button>
      </span>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import BaseModal from '../reusable/BaseModal.vue'
import { functions } from '../../firebase'
import { FunctionNames } from '@/enums/FunctionNames'
import { CreateNewUserPayload, SanitizedUsersReturnData } from 'types/callable'
import { CustomClaims } from 'types/custom-claim'
import { SimplifiedUserColumnData } from '@/views/ManageUsers.vue'

export default defineComponent({
  components: { BaseModal },
  emits: ['close', 'confirm'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    existingUsers: {
      type: Array as PropType<SimplifiedUserColumnData[]>,
      default: [],
    },
  },
  data() {
    return {
      fullName: '',
      email: '',
      password: '',
      repeatPassword: '',
      role: 'taxnitro-reviewer' as CustomClaims['role'],
      roleOptions: [
        { title: 'Tax Nitro Reviewer', value: 'taxnitro-reviewer' },
        { title: 'Right Way Reviewer', value: 'rightway-reviewer' },
        { title: 'Admin', value: 'admin' },
      ],
      errors: [] as string[],
      loading: false,
    }
  },
  methods: {
    async create(): Promise<void> {
      this.loading = true
      // validation
      try {
        const valids = this.validation()
        if (!valids) {
          this.loading = false
          return
        }

        // call callable function to create user
        const data: CreateNewUserPayload = {
          email: this.email,
          password: this.password,
          displayName: this.fullName,
          role: this.role,
        }

        const callableRes = await functions.httpsCallable(
          FunctionNames.ADMIN_CREATE_NEW_USER
        )(data)

        const userRecord: SanitizedUsersReturnData = callableRes.data

        this.$emit('confirm', userRecord)
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    validation(): boolean {
      this.errors = []
      const errors: string[] = []
      // Validate name
      const validName = this.fullName.length > 5
      if (!validName) {
        errors.push('Name must be at least 6 characters.')
      }

      // Validate email

      const validEmail =
        !!this.email && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.email)
      if (!validEmail) {
        errors.push('Invalid email address')
      }

      const emailAlreadyExists = this.existingUsers.find(
        user => user.email === this.email
      )

      if (emailAlreadyExists) {
        errors.push('Email address already registered.')
      }

      // Validate password
      if (this.password.length < 7) {
        errors.push('Password must be at least 6 characters.')
      }
      if (this.password !== this.repeatPassword) {
        errors.push('Please repeat your password correctly.')
      }

      this.errors = errors

      if (errors.length > 0) {
        return false
      } else {
        return true
      }
    },
  },
})
</script>

<style scoped></style>
