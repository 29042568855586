
import { FunctionNames } from '@/enums/FunctionNames'
import { functions } from '@/firebase'
import { SanitizedUsersReturnData } from 'types/callable'
import { computed, defineComponent, ref } from 'vue'
import { RouteName } from '../router/RouteName'
import Spinner from '../components/reusable/BaseSpinner.vue'
import AddUserModal from '../components/manage-users/AddUserModal.vue'
import RowDropdown from '../components/manage-users/RowDropdown.vue'
import { CustomClaims } from 'types/custom-claim'

export type SimplifiedUserColumnData = {
  fullName: string
  email: string
  role: CustomClaims['role']
  uid: string
  created: string
  recentLogin: string
}

export default defineComponent({
  components: {
    Spinner,
    AddUserModal,
    RowDropdown,
  },
  setup() {
    const routeName = RouteName
    const usersData = ref<SanitizedUsersReturnData[]>([])
    const errorMessage = ref('test')
    const loading = ref(true)
    // const simplifiedUsersData = ref<SimplifiedUserColumnData[]>([])
    const showAddUser = ref(false)

    const addUser = (data: SanitizedUsersReturnData) => {
      usersData.value.push(data)
      // simplifiedUsersData.value.push({
      //   fullName: data.fullName,
      //   email: data.email,
      //   role: data.customClaims.role,
      //   uid: data.uid,
      //   created: data.createdAt,
      //   recentLogin: data.lastLogin,
      // })
      showAddUser.value = false
    }
    const editUser = (newData: SanitizedUsersReturnData) => {
      usersData.value.map(userData => {
        if (userData.uid !== newData.uid) return usersData
        return newData
      })
      // simplifiedUsersData.value.push({
      //   fullName: data.fullName,
      //   email: data.email,
      //   role: data.customClaims.role,
      //   uid: data.uid,
      //   created: data.createdAt,
      //   recentLogin: data.lastLogin,
      // })
    }

    const deleteUser = (userDataToDelete: SanitizedUsersReturnData) => {
      usersData.value = usersData.value.filter(
        userData => userData.uid !== userDataToDelete.uid
      )
    }

    functions
      .httpsCallable(FunctionNames.ADMIN_LIST_ALL_USERS)()
      .then(res => {
        const users = res.data as SanitizedUsersReturnData[]
        usersData.value = users
        // simplifiedUsersData.value = usersData.map(user => ({
        //   fullName: user.fullName,
        //   email: user.email,
        //   role: user.customClaims.role,
        //   uid: user.uid,
        //   created: user.createdAt,
        //   recentLogin: user.lastLogin,
        // }))
        loading.value = false
      })

    const simplifiedUsersData = computed(() =>
      usersData.value.map(user => ({
        fullName: user.fullName,
        email: user.email,
        role: user.customClaims.role,
        uid: user.uid,
        created: user.createdAt,
        recentLogin: user.lastLogin,
      }))
    )

    return {
      errorMessage,
      simplifiedUsersData,
      routeName,
      loading,
      showAddUser,
      addUser,
      editUser,
      deleteUser,
    }
  },
})
