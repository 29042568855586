<template>
  <div class="max-w-6xl mx-auto py-6 px-3 sm:px-6 lg:px-8">
    <div class="flex justify-end mb-4">
      <button
        :disabled="loading"
        class="mt-3 relative flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-brand hover:bg-orange-500 focus:outline-none transition duration-150 ease-in-out disabled:opacity-75 disabled:bg-gray-400 disabled:text-gray-600"
        @click="showAddUser = true"
      >
        Create new user
      </button>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <div
              v-if="loading"
              class="min-w-full h-48 flex justify-center items-center"
            >
              <spinner class="border-brand" />
            </div>
            <table v-else class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    User
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Role
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    UID
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Recent Login
                  </th>
                  <th class="px-6 py-3 bg-gray-50"></th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="userData in simplifiedUsersData" :key="userData.uid">
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <div class="flex flex-col items-start justify-items-center">
                      <div class="text-sm leading-5 font-medium text-gray-900">
                        {{ userData.fullName }}
                      </div>
                      <div class="text-sm leading-5 text-gray-500">
                        {{ userData.email }}
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap">
                    <p class="text-xs font-semibold">
                      {{ userData.role }}
                    </p>
                  </td>
                  <td class="px-6 py-4 w-64">
                    <p class="text-xs font-semibold text-gray-500">
                      {{ userData.uid }}
                    </p>
                  </td>
                  <td class=" px-3 py-4 text-xs text-gray-500">
                    <p class="text-xs font-semibold text-gray-500">
                      {{ userData.created }}
                    </p>
                  </td>
                  <td class=" px-3 py-4 text-xs text-gray-500">
                    <p class="text-xs font-semibold text-gray-500">
                      {{ userData.recentLogin }}
                    </p>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"
                  >
                    <row-dropdown
                      :userData="userData"
                      :existingUsers="simplifiedUsersData"
                      @edit-user="editUser"
                      @delete-user="deleteUser"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <add-user-modal
      :show="showAddUser"
      :existingUsers="simplifiedUsersData"
      @close="showAddUser = false"
      @confirm="addUser"
    />
  </div>
</template>

<script lang="ts">
import { FunctionNames } from '@/enums/FunctionNames'
import { functions } from '@/firebase'
import { SanitizedUsersReturnData } from 'types/callable'
import { computed, defineComponent, ref } from 'vue'
import { RouteName } from '../router/RouteName'
import Spinner from '../components/reusable/BaseSpinner.vue'
import AddUserModal from '../components/manage-users/AddUserModal.vue'
import RowDropdown from '../components/manage-users/RowDropdown.vue'
import { CustomClaims } from 'types/custom-claim'

export type SimplifiedUserColumnData = {
  fullName: string
  email: string
  role: CustomClaims['role']
  uid: string
  created: string
  recentLogin: string
}

export default defineComponent({
  components: {
    Spinner,
    AddUserModal,
    RowDropdown,
  },
  setup() {
    const routeName = RouteName
    const usersData = ref<SanitizedUsersReturnData[]>([])
    const errorMessage = ref('test')
    const loading = ref(true)
    // const simplifiedUsersData = ref<SimplifiedUserColumnData[]>([])
    const showAddUser = ref(false)

    const addUser = (data: SanitizedUsersReturnData) => {
      usersData.value.push(data)
      // simplifiedUsersData.value.push({
      //   fullName: data.fullName,
      //   email: data.email,
      //   role: data.customClaims.role,
      //   uid: data.uid,
      //   created: data.createdAt,
      //   recentLogin: data.lastLogin,
      // })
      showAddUser.value = false
    }
    const editUser = (newData: SanitizedUsersReturnData) => {
      usersData.value.map(userData => {
        if (userData.uid !== newData.uid) return usersData
        return newData
      })
      // simplifiedUsersData.value.push({
      //   fullName: data.fullName,
      //   email: data.email,
      //   role: data.customClaims.role,
      //   uid: data.uid,
      //   created: data.createdAt,
      //   recentLogin: data.lastLogin,
      // })
    }

    const deleteUser = (userDataToDelete: SanitizedUsersReturnData) => {
      usersData.value = usersData.value.filter(
        userData => userData.uid !== userDataToDelete.uid
      )
    }

    functions
      .httpsCallable(FunctionNames.ADMIN_LIST_ALL_USERS)()
      .then(res => {
        const users = res.data as SanitizedUsersReturnData[]
        usersData.value = users
        // simplifiedUsersData.value = usersData.map(user => ({
        //   fullName: user.fullName,
        //   email: user.email,
        //   role: user.customClaims.role,
        //   uid: user.uid,
        //   created: user.createdAt,
        //   recentLogin: user.lastLogin,
        // }))
        loading.value = false
      })

    const simplifiedUsersData = computed(() =>
      usersData.value.map(user => ({
        fullName: user.fullName,
        email: user.email,
        role: user.customClaims.role,
        uid: user.uid,
        created: user.createdAt,
        recentLogin: user.lastLogin,
      }))
    )

    return {
      errorMessage,
      simplifiedUsersData,
      routeName,
      loading,
      showAddUser,
      addUser,
      editUser,
      deleteUser,
    }
  },
})
</script>

<style scoped></style>
