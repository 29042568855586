
import { defineComponent, PropType } from 'vue'
import BaseModal from '../reusable/BaseModal.vue'
import { functions } from '../../firebase'
import { FunctionNames } from '@/enums/FunctionNames'
import { CreateNewUserPayload, SanitizedUsersReturnData } from 'types/callable'
import { CustomClaims } from 'types/custom-claim'
import { SimplifiedUserColumnData } from '@/views/ManageUsers.vue'

export default defineComponent({
  components: { BaseModal },
  emits: ['close', 'confirm'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    existingUsers: {
      type: Array as PropType<SimplifiedUserColumnData[]>,
      default: [],
    },
  },
  data() {
    return {
      fullName: '',
      email: '',
      password: '',
      repeatPassword: '',
      role: 'taxnitro-reviewer' as CustomClaims['role'],
      roleOptions: [
        { title: 'Tax Nitro Reviewer', value: 'taxnitro-reviewer' },
        { title: 'Right Way Reviewer', value: 'rightway-reviewer' },
        { title: 'Admin', value: 'admin' },
      ],
      errors: [] as string[],
      loading: false,
    }
  },
  methods: {
    async create(): Promise<void> {
      this.loading = true
      // validation
      try {
        const valids = this.validation()
        if (!valids) {
          this.loading = false
          return
        }

        // call callable function to create user
        const data: CreateNewUserPayload = {
          email: this.email,
          password: this.password,
          displayName: this.fullName,
          role: this.role,
        }

        const callableRes = await functions.httpsCallable(
          FunctionNames.ADMIN_CREATE_NEW_USER
        )(data)

        const userRecord: SanitizedUsersReturnData = callableRes.data

        this.$emit('confirm', userRecord)
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    validation(): boolean {
      this.errors = []
      const errors: string[] = []
      // Validate name
      const validName = this.fullName.length > 5
      if (!validName) {
        errors.push('Name must be at least 6 characters.')
      }

      // Validate email

      const validEmail =
        !!this.email && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.email)
      if (!validEmail) {
        errors.push('Invalid email address')
      }

      const emailAlreadyExists = this.existingUsers.find(
        user => user.email === this.email
      )

      if (emailAlreadyExists) {
        errors.push('Email address already registered.')
      }

      // Validate password
      if (this.password.length < 7) {
        errors.push('Password must be at least 6 characters.')
      }
      if (this.password !== this.repeatPassword) {
        errors.push('Please repeat your password correctly.')
      }

      this.errors = errors

      if (errors.length > 0) {
        return false
      } else {
        return true
      }
    },
  },
})
