<template>
  <div class="static">
    <button
      class="focus:outline-none"
      @click="show = !show"
      @blur="show = false"
    >
      <svg
        class="h-5 w-5 text-gray-600 hover:text-gray-800"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
        />
      </svg>
    </button>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="show"
        class="origin-top-right right-0 w-24 mr-8 absolute mt-2 rounded-md shadow-lg"
      >
        <div
          class="py-1 rounded-md bg-white shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <a
            @click="showEditModal = true"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-300 cursor-pointer"
            role="menuitem"
            >Edit User</a
          >

          <a
            @click="showDeleteModal = true"
            class="block px-4 py-2 text-sm text-red-700 hover:bg-gray-300 cursor-pointer"
            role="menuitem"
            >Delete</a
          >
        </div>
      </div>
    </transition>
    <edit-user-modal
      :show="showEditModal"
      @close="showEditModal = false"
      :userData="userData"
      :existingUsers="existingUsers"
      @confirm="onEditConfirm"
    />
    <delete-user-modal
      :loading="isDeleting"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
      :userData="userData"
      @confirm="deleteUser"
    />
  </div>
</template>

<script lang="ts">
import { FunctionNames } from '@/enums/FunctionNames'
import { functions } from '@/firebase'
import { SimplifiedUserColumnData } from '@/views/ManageUsers.vue'
import {
  DeleteUserPayload,
  DeleteUserReturnData,
  SanitizedUsersReturnData,
} from 'types/callable'
import { defineComponent, PropType } from 'vue'
import EditUserModal from './EditUserModal.vue'
import DeleteUserModal from './DeleteUserModal.vue'

export default defineComponent({
  emits: ['edit-user', 'delete-user'],
  components: { EditUserModal, DeleteUserModal },
  props: {
    userData: {
      type: Object as PropType<SimplifiedUserColumnData>,
      required: true,
    },
    existingUsers: {
      type: Array as PropType<SimplifiedUserColumnData[]>,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      showEditModal: false,
      showDeleteModal: false,
      isDeleting: false,
    }
  },
  methods: {
    onEditConfirm(data: SanitizedUsersReturnData) {
      this.$emit('edit-user', data)
      this.showEditModal = false
    },
    deleteUser() {
      this.isDeleting = true
      const payload: DeleteUserPayload = {
        uid: this.userData.uid,
      }
      functions
        .httpsCallable(FunctionNames.ADMIN_DELETE_USER)(payload)
        .then(res => {
          const data = res.data as DeleteUserReturnData
          if (data) {
            this.$emit('delete-user', this.userData)
          } else {
            console.log('Failed to delete user. Contact support.')
          }
          this.isDeleting = false
          this.showDeleteModal = false
        })
    },
  },
})
</script>

<style scoped></style>
