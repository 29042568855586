
import { FunctionNames } from '@/enums/FunctionNames'
import { functions } from '@/firebase'
import { SimplifiedUserColumnData } from '@/views/ManageUsers.vue'
import {
  DeleteUserPayload,
  DeleteUserReturnData,
  SanitizedUsersReturnData,
} from 'types/callable'
import { defineComponent, PropType } from 'vue'
import EditUserModal from './EditUserModal.vue'
import DeleteUserModal from './DeleteUserModal.vue'

export default defineComponent({
  emits: ['edit-user', 'delete-user'],
  components: { EditUserModal, DeleteUserModal },
  props: {
    userData: {
      type: Object as PropType<SimplifiedUserColumnData>,
      required: true,
    },
    existingUsers: {
      type: Array as PropType<SimplifiedUserColumnData[]>,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      showEditModal: false,
      showDeleteModal: false,
      isDeleting: false,
    }
  },
  methods: {
    onEditConfirm(data: SanitizedUsersReturnData) {
      this.$emit('edit-user', data)
      this.showEditModal = false
    },
    deleteUser() {
      this.isDeleting = true
      const payload: DeleteUserPayload = {
        uid: this.userData.uid,
      }
      functions
        .httpsCallable(FunctionNames.ADMIN_DELETE_USER)(payload)
        .then(res => {
          const data = res.data as DeleteUserReturnData
          if (data) {
            this.$emit('delete-user', this.userData)
          } else {
            console.log('Failed to delete user. Contact support.')
          }
          this.isDeleting = false
          this.showDeleteModal = false
        })
    },
  },
})
